import React from "react";
import AboutImg from "../images/about.png";
import styled from "styled-components";

const About = () => {
  return (
    <StyledAbout id="about">
      <div className="wrapper">
        <Container>
          <h2>about me</h2>
          <p>
            Enthusiastic web developer specializing in web development. Fueled by a passion for crafting engaging user interfaces (UIs) using JavaScript, React, and Figma. With over 3 years of experience, I consistently strive for innovative solutions that deliver impactful results. Driven by a desire to learn and grow, I'm eager to take on new challenges and contribute to shaping the future of technology.
          </p>
        </Container>

        <div className="image" style={{ width: "100%" }}>
          <img src={AboutImg} alt="" />
        </div>
      </div>
    </StyledAbout>
  );
};
const Container = styled.div`
  z-index: 2;
  align-self: center;
  width: 100%;
  max-width: 60rem;
`;
const StyledAbout = styled.section`
  background-color: var(--accent-tent);
  margin: 0 calc(-50vw + 50%);

  .wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 15rem 5rem;
    margin-top: 10rem;
    max-width: 1560px;
    margin: 0 auto;

  @media screen and (max-width: 990px) {
    margin-top: 5rem;
    grid-template-columns: 1fr;
  }
  }

  h2 {
    margin-bottom: 0.8rem;
    color: var(--text-dark);
  }
  p {
    color: var(--text-light);
    font-weight: 600;
    font-size: 1.8rem;

    span {
      position: relative;

      color: var(--accent-color);
      font-family: "Pacifico", cursive;

      img {
        position: absolute;
        top: -100%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        width: 20rem;
        transition: opacity 300ms ease-in-out;
      }
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
  .image {
    position: absolute;
    bottom: -4px;
    right: 0;
    max-width: 35rem;
    justify-self: end;
    align-self: flex-end;
    text-align: end;
    z-index: 1;
    img {
      width: calc(100vw / 3);
      max-width: 36rem;
    }
  }
`;

export default About;
