import React from "react";
import Footer from "./components/Footer";
import { GlobalStyle } from "./components/GlobalStyle";
import Projects from "./pages/Projects";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
function App() {
  return (
    <div className="App" style={{ maxWidth: "1368px", margin: "0 auto" }}>
      <GlobalStyle />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route exact path="/projects" element={<Projects />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
