import React from "react";
import Github from "../images/Github.svg";
import Linkedin from "../images/linkedin.svg";
import styled from "styled-components";
import { File } from "lucide-react";

const Footer = () => {
  return (
    <StyledFooter id="footer">
      <h3>the best for you</h3>
      <div>
        <a href="https://drive.google.com/file/d/1M11BArv1X6O13SeBX6EwRmwXztJo5lbt/view?usp=drive_link">
          <File color="white" fill="white" />
        </a>
        <a href="https://www.linkedin.com/in/mohamed-salah-b97460217">
          <img src={Linkedin} alt="linkedin-icon" />
        </a>
        <a href="https://github.com/mohammed5420">
          <img src={Github} alt="GitHub-icon" />
        </a>
      </div>
      <div>

        <a href="mailto:mohamed.elbadwi.dev@gmail.com" className="email">Mohamed.elbadwi.dev@gmail.com</a>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: 5rem;
  background-color: var(--s-color);
  margin: 0 calc(-50vw + 50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    color: #f2f2f2;
    margin-bottom: 1rem;
  }

  img {
    display: inline-block;
    width: 24px;
    transition: all 0.3s ease;
    margin: 0 1rem 0;

    &:hover {
      transform: scale(1.04);
    }
  }

  img:nth-child(2) {
    left: 55%;
  }

  .email {
    color: white;
    font-size: 2rem;
  }
`;
export default Footer;
