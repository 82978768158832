import React from "react";
import styled from "styled-components";
import appLandingPage from "../images/applabapp-min.png";
import chatify from "../images/chatify-app-min.png";
import memofy from "../images/memofy-md-min.png";
import hyperMeet from "../images/hyperMeet.png";
import Project from "./Project";
import { Link } from "react-router-dom";

const Projects = () => {
  const data = [
    {
      id: 1,
      name: "Memofy 🗂",
      image: memofy,
      desc: "It's a Nodejs API allows you to do CRUD operations with flashcards including games to help you memorize better with implemented Spaced Repetition methodology.",
      url: "https://flash-cards-production.up.railway.app/api/v1/docs",
      github: "https://github.com/mohammed5420/flash-cards",
    },
    {
      id: 2,
      name: "Chatify 🗯",
      image: chatify,
      desc: "It's a MERN stack socket.io chat App, You can join and create public or private rooms along with ability and invite your friends to talk about interesting topic.",
      url: "https://chatify-rooms.vercel.app/",
      github: "https://github.com/mohammed5420/chatify",
    },
    {
      id: 3,
      name: "AppLab 😎",
      image: appLandingPage,
      desc: "It's a Landing page made with Reactjs for a Mobile app to showcase all the features and how it works with an elegant interactive design.",
      url: "https://applabapp.netlify.app/",
      github: "https://github.com/mohammed5420/landing-website",
    },
    {
      id: 4,
      name: "HyperMeet",
      image: hyperMeet,
      desc: `HyperMeet is a video calling app made with Agora SDK, Nextjs, and Typescript. \nHyperMeet was the second winner of the Dennis lvy's hackathon.`,
      url: "https://hypermeet.vercel.app",
      github: "https://github.com/mohammed5420/hackathon",
    },
  ];
  return (
    <StyledProject id="projects">
      <TitleContainer>
        awesome projects <br />
        i've <span>built</span>
      </TitleContainer>
      <div className="container">
        {data.map((pro) => (
          <Project
            key={pro.id}
            name={pro.name}
            id={pro.id}
            github={[pro.github]}
            url={[pro.url]}
            desc={pro.desc}
            image={pro.image}
          />
        ))}
      </div>
      <div className="btn-container">
        <Link
          className="btn"
          to="/projects"
          onClick={() => {
            document.getElementsByTagName("html")[0].style.scrollBehavior =
              "initial";
          }}
        >
          see more
        </Link>
      </div>
    </StyledProject>
  );
};

const TitleContainer = styled.h2`
  margin-bottom: 8rem;
  text-align: center;

  span {
    color: var(--accent-color);
    font-family: "Pacifico", cursive;
  }
`;

const StyledProject = styled.section`
  padding: 7rem 5rem;
  margin: 0 calc(-50vw + 50%);
  background-color: var(--s-color);
  color: #fff;

  .container {
    max-width: 1560px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    gap: 4rem;
    margin: 0 auto 4rem;
  }

  .btn-container {
    text-align: center;
    margin-top: 2rem;
  }
`;

export default Projects;
